import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import { ProductDevServicesProps } from '../../components/Props/software-product-development'
import styled from 'styled-components'
import SEO from 'components/Seo'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import { graphql } from 'gatsby'
import { images } from 'config/images'

import NewBenefitWork from 'components/NewBenefitWork/NewBenefitWork'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import DevelopSoftAgile from 'components/DevelopSoftAgile/DevelopSoftAgile'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const ProductDevServicesPage = styled.div`
  .banner {
    .banner-content {
      max-width: 940px;
      p {
        max-width: 780px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
  .ppcbenefit-section {
    .heading-wrapper {
      max-width: 740px;
    }
  }
`

const DDT = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout
      mainClass="product-dev-service services-page"
      location={location}
      data={{
        img: images.contactJordan,
        img2x: images.contactJordan2x,
        name: 'Jordan Walker',
        description:
          'Creating UX driven software products to build a growth engine for your organization.',
      }}
    >
      <SEO
        title="Software Product Development Services Company"
        description="Software product design and development services using Lean and Agile principles. Use rapid prototyping and iterative feedback loop to reach product-market-fit early."
        keywords={[`simform`]}
        links={[
          {
            href:
              'https://www.simform.com/services/software-product-development/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content:
              'https://www.simform.com/services/software-product-development/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <ProductDevServicesPage>
        <Banner
          bannerData={ProductDevServicesProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={ProductDevServicesProps} />
          <CustomDevelopment
            customServicedata={ProductDevServicesProps.customDevProps}
          />
        </div>
        <ProcessFlow
          ProcessFlowData={ProductDevServicesProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <CaseStudySlider caseStudydata={ProductDevServicesProps} {...props} />
        <CustomerReviewSlider
          customReviewSliderdata={ProductDevServicesProps}
          {...props}
        />
        <DevelopSoftAgile developSoftAgiledata={ProductDevServicesProps} />

        <SimformGuaranteeSec
          SimformGuaranteeProps={ProductDevServicesProps.SimformGuaranteeData}
          posterImage={posterImage}
        />
        <FaqSection faqSectiondata={ProductDevServicesProps} />
        <FeatureResources
          featureresourceData={ProductDevServicesProps}
          {...props}
        />
      </ProductDevServicesPage>
    </Layout>
  )
})

export default DDT

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/software-product-dev-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 686) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/home-automation-using-iot-cover-image@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(relativePath: { regex: "/blog-gps-tracking.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-estimate-custom-software@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/testing-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 683) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/ill-buildproducts@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/ill-refined@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/ill-discoverysprint@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/ill-productsprint@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/ill-developsprint@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
